<template>
	<div class="coupon-boxi">
		<div class="coupon-text">券</div>
		<div class="coupon-money">¥ {{ couponMoney }}</div>
	</div>
</template>

<script>
export default {
	props: {
		couponMoney: {
			type: [Number, String],
			default: 0
		}
	},
	data () {
		return {}
	},
	computed: {},
	created () {},
	mounted () {},
	methods: {}
}
</script>

<style scoped>
.coupon-boxi {
	box-sizing: border-box;
	padding: 10px 0;
	display: flex;
	align-items: center;
}

.coupon-text {
	padding: 0 3px;
	margin: 0;
	background: #fd7100;
	color: #fff;
	font-size: 12px;
	background: linear-gradient(0deg, #ff4b42, #ff753c);
	height: 18px;
	vertical-align: middle;
	line-height: 18px;
}

.coupon-money {
	padding: 0 2px;
	margin: 0;
	border: 1px solid #fd7100;
	color: #fd7100;
	font-size: 12px;
	height: 18px;
	vertical-align: middle;
	line-height: 18px;
}

</style>
