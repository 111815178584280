<template>
  <div class="goods-item" @click="_goto">
    <div class="goods-image">
      <el-image :src="goods.main_image" lazy></el-image>
    </div>
    <div class="goods-content">
      <div class="goods-title">{{ goods.goods_title }}</div>
      <div class="goods-extra">
        <Coupon :couponMoney="goods.coupon_amount" />
				<div class="goods-price">
					<span class="price-text">券后价：</span>
					<span class="price">¥ {{ goods.goods_price }}</span>
				</div>
      </div>
			<div class="share-content">{{ goods.content }}</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Coupon from '@/views/pc/components/Coupon'
import { Image } from 'element-ui';

Vue.use(Image)

export default {
	props: {
		goods: {
			type: Object,
			default: function() {
				return {}
			}
		}
	},
  components: {
		Coupon
	},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
		_goto() {
			if(!this.goods) {
				return;
			}

			this.$router.push({name: 'GoodsItem', query: {id: this.goods.goods_id}})
		}
	},
};
</script>

<style scoped lang="scss">
@import "~@/css/variables.scss";

.goods-item {
	box-sizing: border-box;
	padding: 10px;
	width: 100%;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	background: #fff;
	margin-bottom: 10px;
	border-radius: 10px;
}

.goods-image {
	width: 100%;
	max-height: 220px;
	border-radius: 8px;
	overflow: hidden;
	font-size: 0;
	margin-bottom: 20px;
}

.goods-content {
	flex: 1;
}

.goods-title {
	height: 50px;
	overflow: hidden;
	line-height: 25px;
	font-size: 14px;
	color: #333;
}

.goods-price {
	padding: 10px 0;
	font-size: 15px;
	color: $--price-color;
	font-weight: 600;
}

.goods-tools {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.tools-left {
	display: flex;
	align-items: center;
}
.goods-extra {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.share-content {
	height: 250px;
	border-radius: 10px;
	background: #f8f8f8;
	line-height: 30px;
	font-size: 14px;
	color: #666;
	padding: 10px;
	white-space: pre-wrap;
	overflow: hidden;
}

</style>
