<template>
	<div class="wrapper">
		<el-skeleton :loading="loading" animated :count="10">
			<ul class="table">
					<li class="goods-item" v-for="(goods, i) in table_data" :key="i">
						<share-goods :goods="goods"></share-goods>
					</li>

					<el-empty description="暂无更多数据" v-if="table_data.length <= 0"></el-empty>
			</ul>
		</el-skeleton>
	</div>
</template>

<script>
import Vue from 'vue';
import ShareGoods from '@/views/pc/components/ShareGoods'
import { Skeleton, SkeletonItem, Empty } from 'element-ui';
Vue.use(Skeleton).use(SkeletonItem).use(Empty)


import {getShareContent} from '@/api/pc'

export default {
	components: {
		ShareGoods
	},
	data () {
		return {
			loading: true,
			table_data: []
		}
	},
	computed: {},
	created () {},
	mounted () {
		this._loadData()
	},
	methods: {
		_loadData() {
			getShareContent({rows: 50}).then(res => {
				this.loading = false
				if(res.errCode === 0) {
					this.table_data = res.data
				}
			}).catch(err => {
				this.loading = false
			})
		},
	}
}
</script>

<style scoped>
.table {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.goods-item {
	width: 240px;
}
</style>
